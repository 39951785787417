import React, {useState, useRef} from 'react';
import { push } from "connected-react-router";
import { NavigationMenu } from '../navigation-menu/navigation-menu';
import _ from 'lodash'
import {toggleMobileMenu, hiddenMobileMenu, showMobileMenu} from '../../pages/pagesSlice';

import './burger.scss';
import './header.scss';
import { useDispatch, useSelector } from 'react-redux';

export const Header = () => {
    const [menuOpen, setMenuOpen] = useState(false);
    const mobileMenu = useSelector(state => state.pages.mobileMenu);
    const dispatch = useDispatch();

    const contacts = useSelector((state) => state.pages.contacts);

    let lastScroll = 0
    let directionDown = false

    const handleScroll = () => {

        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        if (scrollTop > lastScroll && !directionDown) {
            directionDown = true;
            dispatch(hiddenMobileMenu());
        } else if (scrollTop < lastScroll && directionDown) {
            directionDown = false;
        }
        lastScroll = scrollTop;
    }

    let burger = useRef(null);

    window.addEventListener('scroll', (e) => {
        handleScroll();
    });

    return(
        <React.Fragment>
            <header className='header'>
                <div className='header__logo-wrapper' onClick={() => {
                    dispatch(push('/'));
                    window.scrollTo(0, 0);
                }}>
                    <a className='header__logo-image'></a>
                </div>
                <NavigationMenu />

                <svg className={`ham ham6 ${mobileMenu ? 'active' : ''}`} viewBox="0 0 100 100" width="80" ref={burger} onClick={() => dispatch(toggleMobileMenu())}>
                    <path
                        className="line top"
                        d="m 30,33 h 40 c 13.100415,0 14.380204,31.80258 6.899646,33.421777 -24.612039,5.327373 9.016154,-52.337577 -12.75751,-30.563913 l -28.284272,28.284272" />
                    <path
                        className="line middle"
                        d="m 70,50 c 0,0 -32.213436,0 -40,0 -7.786564,0 -6.428571,-4.640244 -6.428571,-8.571429 0,-5.895471 6.073743,-11.783399 12.286435,-5.570707 6.212692,6.212692 28.284272,28.284272 28.284272,28.284272" />
                    <path
                        className="line bottom"
                        d="m 69.575405,67.073826 h -40 c -13.100415,0 -14.380204,-31.80258 -6.899646,-33.421777 24.612039,-5.327373 -9.016154,52.337577 12.75751,30.563913 l 28.284272,-28.284272" />
                </svg>
            </header>
            <div className='open-menu' style={mobileMenu ? {opacity: 1, zIndex: 99999} : {opacity: 0, zIndex: -21}}>
                <NavigationMenu closeMenu={() => setMenuOpen(false)}/>
                <ul className='open-menu__list-phones'>
                    {contacts && contacts.length > 0 ? _.filter(contacts, c => c.type == 'phone').map((x, i) => (<li key={i} className='open-menu__item-phone'><a href={`tel:${x.value}`}>{x.value}</a></li>)) : null}
                </ul>
            </div>
        </React.Fragment>
    )
}
