import React from "react";

import "./scroll-down.scss";
import { CaretDown } from "./ui/caret-down";

function ScrollDown({
    className,
    text,
    isWrapped = true,
}: {
    className?: string;
    text?: string;
    isWrapped?: boolean;
}) {
    const arrow = (
        <div className={`scroll-down ${className}`}>
            <div className="scroll-down__text">{text}</div>
            <div className="scroll-down__icon">
                <CaretDown />
            </div>
        </div>
    );

    return isWrapped ? (
        <div className="scroll-down__wrapper">{arrow}</div>
    ) : (
        arrow
    );
}

export default ScrollDown;
