export const navigations = [
    {
        text: 'Квесты',
        elementId: 'quests',
        path: '/#quests',
    },
    {
        text: 'Контакты',
        elementId: 'contacts',
        path: '#contacts',
    },
    // {
    //     text: 'Заказать праздник',
    //     elementId: 'Holiday',
    //     path: '/holiday'
    // },
    // {
    //     text: 'Выездные мероприятия',
    //     elementId: 'Holiday',
    //     path: '/holiday-2'
    // },
    {
        text: 'Сертификаты',
        elementId: 'certificate',
        path: '/certificate'
    },

];
