import React from 'react';
import { QuestInfoHeader } from './quest-info-header/quest-info-header';
import { QuestInfoBody } from './quest-info-body/quest-info-body';


export const QuestInfo = ({ quest }) => {
    return (
        <React.Fragment>
            <QuestInfoHeader  quest={quest}/>
            <QuestInfoBody quest={quest} />
        </React.Fragment>
    );
}