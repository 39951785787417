import { AppState } from "../../app/store";
import { connect } from "react-redux";
import {
    setPersonName,
    setparticipantsCount,
    setPhoneNumber,
    reserve,
    close,
} from "./reservationFormSlice";
import React from "react";
import { createAsyncAction } from "../../middleware/asyncMiddleware";
import "./reservation-form.scss";
import "../../../node_modules/animate.css/animate.css";
import { DayOfWeek } from "../../pages/questPage/questPageSlice";
import _ from "lodash";

interface ReservationFormProps {
    close(): void;
    isWaiting: boolean;
    reserve(
        questId: number,
        dateTimeString: string,
        personName: string,
        phoneNumber: string,
        participantsCount: number,
        shownPrice: number
    ): void;
    setparticipantsCount(value: any): void;
    setPhone(value: any): void;
    setName(value: any): void;
    personName: string;
    phoneNumber: string;
    dateTimeString: string;
    dayOfWeek: DayOfWeek;
    participantsCount: number;
    pricePerPersonFrom: number;
    priceHours: {
        [day in DayOfWeek]: { from: string; to: string; price: number }[];
    };
    questId: number;
    participantsCountBoarders: { from: number; to: number };
}
// animate__fadeInDown animate__delay-1s
const ReservationForm = (props: ReservationFormProps) => {
    return (
        <React.Fragment>
            <form
                id="reservation-form-id"
                className={`reservation-form`}
                onSubmit={(e) => {
                    e.preventDefault();

                    props.reserve(
                        props.questId,
                        props.dateTimeString,
                        props.personName,
                        props.phoneNumber,
                        props.participantsCount,
                        props.participantsCount *
                            getPricePerPerson(
                                props.priceHours,
                                props.dateTimeString
                            )
                    );
                }}
            >
                <div className="reservation-form__wrapper-text">
                    <span className="reservation-form__text">Бронирование</span>
                    <span className="reservation-form__date">
                        {props.dateTimeString}
                    </span>
                </div>
                <div className="reservation-form__wrapper-input">
                    <div className="reservation-form__wrapper-top-input">
                        <p className="reservation-form__wrapper-lable-input">
                            <label className="reservation-form__label">
                                Ваше имя
                            </label>
                            <input
                                required
                                className="reservation-form__input"
                                defaultValue={props.personName}
                                placeholder="Имя"
                                type="text"
                                onChange={(e: any) =>
                                    props.setName(e.target.value)
                                }
                            />
                        </p>
                        <p className="reservation-form__wrapper-lable-input">
                            <label className="reservation-form__label">
                                Ваш телефон
                            </label>
                            <input
                                required
                                minLength={10}
                                type="text"
                                className="reservation-form__input"
                                id="phone"
                                defaultValue={props.phoneNumber}
                                placeholder="Телефон"
                                onChange={(e: any) =>
                                    props.setPhone(e.target.value)
                                }
                            />
                        </p>
                        <p className="reservation-form__wrapper-lable-input">
                            <label
                                className="reservation-form__label"
                                htmlFor="#players"
                            >
                                Кол-во участников
                            </label>
                            <input
                                required
                                className="reservation-form__input"
                                minLength={props.participantsCountBoarders.from}
                                maxLength={props.participantsCountBoarders.to}
                                id="players"
                                defaultValue={props.participantsCount}
                                placeholder="Кол-во"
                                type="number"
                                min={1}
                                onChange={(e: any) =>
                                    props.setparticipantsCount(e.target.value)
                                }
                            />
                        </p>
                    </div>
                    <div className="reservation-form__wrapper-bottom-input">
                        {/*{getPricePerPerson(props.priceHours, props.dateTimeString) ? <p className='reservation-form__price'>Сумма: <span className='reservation-form__price-rub'>{props.participantsCount*getPricePerPerson(props.priceHours, props.dateTimeString)} РУБ</span></p>: <React.Fragment/>}*/}
                        <div>
                            <p className="reservation-form__price">
                                Цена от:
                                <span className="reservation-form__price-rub">
                                    {props.pricePerPersonFrom} РУБ за участника
                                </span>
                            </p>
                            {isWeekend(props.dayOfWeek) && (
                                <p className="reservation-form__price-notice">
                                    в выходной день к общей стоимости
                                    добавляется 500₽
                                </p>
                            )}
                        </div>
                        <div className="reservation-form__wrapper-button">
                            {!props.isWaiting ? (
                                <button
                                    type="submit"
                                    className="reservation-form__button"
                                >
                                    {" "}
                                    Бронировать{" "}
                                </button>
                            ) : (
                                <React.Fragment />
                            )}
                            <span
                                className="reservation-form__close"
                                onClick={() => props.close()}
                            >
                                Отменить
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        </React.Fragment>
    );
};

const mapStateToProps = (state: AppState) => ({
    ...state.reservatinForm,
    questId: state.questPage.quest.id,
    participantsCountBoarders: state.questPage.quest.participantsCount,
    priceHours: state.questPage.quest.priceHours,
    pricePerPersonFrom: state.questPage.quest.pricePerPersonFrom,
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        close: () => dispatch(close()),
        setName: (name: string) => dispatch(setPersonName(name)),
        setPhone: (phone: string) => dispatch(setPhoneNumber(phone)),
        setparticipantsCount: (count: number) =>
            dispatch(setparticipantsCount(count)),
        reserve: (
            questId: number,
            dateTimeString: string,
            personName: string,
            phoneNumber: string,
            participantsCount: number,
            shownPrice: number
        ) =>
            dispatch(
                createAsyncAction(
                    reserve.type,
                    `quests/${questId}/reserve`,
                    "POST",
                    {
                        dateTimeString: dateTimeString,
                        personName: personName,
                        phoneNumber: phoneNumber,
                        participantsCount: participantsCount,
                        shownPrice: shownPrice,
                    },
                    `Бронирование на ${dateTimeString} успешно, ожидайте звонок оператора`,
                    "Произошла ошибка при бронировании"
                )
            ),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReservationForm);

function getPricePerPerson(priceHours: any, dateTimeString: string) {
    const [dateString, timeString] = dateTimeString.split(" ");

    const dayPriceHours =
        priceHours[new Date(dateString).getDay() as DayOfWeek];

    return countPrice(timeString, dayPriceHours);
}

function getDayOfWeek(dateTimeString: string) {
    const [dateString] = dateTimeString.split(" ");

    return new Date(dateString).getDay();
}

function isWeekend(dayOfWeek: DayOfWeek) {
    return [DayOfWeek.Saturday, DayOfWeek.Sunday].includes(dayOfWeek);
}

const countPrice = (timeString: string, dayPriceHours: any) => {
    const dayPrice = _.filter(
        dayPriceHours,
        (el: { from: string; to: string; price: number }) => {
            const [startHour, startMinutes] = el.from
                .split(":")
                .map((x) => parseInt(x));
            const [endHour, endMinutes] = el.to
                .split(":")
                .map((x) => parseInt(x));
            const [hours, minutes] = timeString
                .split(":")
                .map((x) => parseInt(x));

            const time =
                startHour * 60 + startMinutes <= hours * 60 + minutes
                    ? hours * 60 + minutes
                    : hours * 60 + minutes + 24 * 60;

            return (
                startHour * 60 + startMinutes <= time &&
                time <
                    endHour * 60 +
                        endMinutes +
                        (endHour < startHour ? 24 * 60 : 0) +
                        1
            );
        }
    );

    const personPrice = dayPrice[0] ? dayPrice[0].price : 0;

    return personPrice;
};
