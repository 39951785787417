import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isWaiting: false,
    isOpen: false,
};

const formSlice = createSlice({
    name: "form",
    initialState,
    reducers: {
        submit(state) {
            state.isWaiting = false;
        },
        submitBegin(state) {
            state.isWaiting = true;
        },
        submitError(state) {
            state.isWaiting = false;
        },
        open(state) {
            state.isOpen = true;
        },
        close(state) {
            state.isOpen = false;
        },
    },
});

export const { submit, open, close } = formSlice.actions;

export default formSlice.reducer;
