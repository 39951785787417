import React from 'react';
import VideoSection from '../video-section/video-section';
import ScrollDown from '../scroll-down/ScrollDown';

import './main-header.scss';

const MainHeader = (props) => {

    let width = window.innerWidth;

    return (
        <header className='main-header'>
            <h1 className='main-header__name-quest'>isolation <br/> quest</h1>
            {/*{width > 1024 && <video disablePictureInPicture loop autoPlay playsInline muted preload={'none'}*/}
            {/*        className={'main-header__video-bg'}>*/}
            {/*    <source src={`${process.env.REACT_APP_MEDIA_BASEPATH}/video.webm`} type='video/webm'/>*/}
            {/*</video>}*/}
            <ScrollDown classMod="scroll-down_b10vh scroll-down_mt1em scroll-down_main" text="У тебя один час, чтобы выбраться" />
        </header>
    )
}

export default MainHeader;
