import "./timetable.scss";
import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../app/store";
import { replaceDate } from "../../functions/replaceDate";
import {
  changeAnotherDateSelected,
  DayOfWeek,
  setIsTimeTableExpanded,
  setShowAnotherDateForm,
  setTimeTableDaysShow,
} from "../../pages/questPage/questPageSlice";
import ReservationForm from "../reservation-form/reservation-form";
import { open, close } from "../reservation-form/reservationFormSlice";

import { Swiper, SwiperSlide } from "swiper/react";

export const TimeTable = () => {
  const startingDate = useSelector(
    (state: AppState) => state.questPage.quest.startingDate
  );
  const endDate = useSelector(
    (state: AppState) => state.questPage.quest.endDate
  );
  const unavailabilityPeriods = useSelector((state: AppState) =>
    state.questPage.quest.unavailabilityPeriods?.map((up) => ({
      from: new Date(up.from),
      to: new Date(up.to),
    }))
  );
  const daysCount = useSelector(
    (state: AppState) => state.questPage.quest.timeTableDaysShow
  );
  const isSelectAnotherDate = useSelector(
    (state: AppState) => state.questPage.isShowAnotherDateForm
  );
  const isTimeTableExpanded = useSelector(
    (state: AppState) => state.questPage.isTimeTableExpanded
  );
  const openHours = useSelector(
    (state: AppState) => state.questPage.quest.openHours
  );
  const timeStepMinutes = useSelector(
    (state: AppState) =>
      state.questPage.quest.prepareTimeMinutes +
      state.questPage.quest.durationTimeMinutes
  );
  const timeZone = useSelector(
    (state: AppState) => state.questPage.quest.timezone
  );
  const selectedRow = useSelector(
    (state: AppState) => state.reservatinForm.rowNumber
  );

  const dates = [];
  const startDate =
    startingDate && new Date(startingDate) > new Date()
      ? new Date(startingDate)
      : new Date();

  const daysFromStartToEnd = endDate
    ? Math.ceil(
        (new Date(endDate).getTime() - startDate.getTime()) /
          (1000 * 60 * 60 * 24)
      ) + 1
    : Number.MAX_VALUE;

  const days = daysCount < daysFromStartToEnd ? daysCount : daysFromStartToEnd;

  for (let i = 0; i < days; i++) {
    const date = calcDateOfTimezone(
      addDaysToDate(startDate, i),
      parseInt(timeZone)
    );
    date.setHours(0, 0, 0, 0);
    dates.push(date);
  }

  useEffect(() => {
    const element = document.getElementById("reservation-form-id");

    if (!element) return;

    const block = window.innerWidth <= 722 ? "end" : "center";

    element.scrollIntoView({ behavior: "smooth", block: block });
  }, [selectedRow]);

  return (
    <React.Fragment>
      <div className={`timetable`}>
        {dates
          .filter(
            (d) =>
              !unavailabilityPeriods?.some((up) => up.from <= d && d <= up.to)
          )
          .map((date: Date, idx: number) => {
            const dayOfWeek = date.getDay() as DayOfWeek;
            const openHour = openHours[dayOfWeek];
            return (
              <DayRow
                key={idx}
                currentDate={calcDateOfTimezone(new Date(), parseInt(timeZone))}
                startHour={openHour.from}
                endHour={openHour.to}
                timeStepMinutes={timeStepMinutes}
                rowIndex={idx}
                date={date}
              />
            );
          })}
        {!endDate ? (
          !isTimeTableExpanded ? (
            <ShowMoreButton newDaysCount={daysCount * 2} />
          ) : !isSelectAnotherDate ? (
            <SelectAnotherDateButton />
          ) : (
            <DayRowWithDatepicker
              minimumDate={calcDateOfTimezone(
                addDaysToDate(startDate, dates.length),
                parseInt(timeZone)
              )}
              rowIndex={dates.length} //+1 ?
              openHours={openHours}
              timeStepMinutes={timeStepMinutes}
            />
          )
        ) : (
          <></>
        )}
      </div>
    </React.Fragment>
  );
};

interface DayRowProps {
  date: Date;
  rowIndex: number;
  startHour: string;
  endHour: string;
  timeStepMinutes: number;
  currentDate?: Date;
}

const DayRow = React.memo((props: DayRowProps) => {
  const startMinutes = toMinutesFromDayStart(props.startHour);
  let endMinutes = toMinutesFromDayStart(props.endHour);

  const isOpen = useSelector(
    (state: AppState) => state.reservatinForm.rowNumber === props.rowIndex
  );

  //example 10:00 - 01:00
  endMinutes =
    endMinutes > startMinutes
      ? endMinutes
      : endMinutes + toMinutesFromDayStart("24:00");

  const timeItems = _.range(startMinutes, endMinutes + 1, props.timeStepMinutes) //inclusive range
    .map((minutes: number, idx: number) => {
      const timeString = minutesToString(minutes);
      const dateString = dateToString(
        minutes < toMinutesFromDayStart("24:00")
          ? props.date
          : addDaysToDate(props.date, 1)
      );

      //Костыльно
      if (
        props.currentDate !== undefined &&
        getDate(props.date, minutes) < props.currentDate
      ) {
        return <React.Fragment />;
      }

      if (window.innerWidth <= 1024) {
        return (
          <React.Fragment>
            <SwiperSlide>
              <TimeItem
                key={idx}
                dispalyString={timeString}
                dateTimeString={`${dateString} ${timeString}`}
                dayOfWeek={props.date.getDay()}
                rowIndex={props.rowIndex}
              />
            </SwiperSlide>
          </React.Fragment>
        );
      }

      return (
        <>
          <TimeItem
            key={idx}
            dispalyString={timeString}
            dateTimeString={`${dateString} ${timeString}`}
            dayOfWeek={props.date.getDay()}
            rowIndex={props.rowIndex}
          />
        </>
      );
    });

  if (window.innerWidth <= 1024) {
    return (
      <React.Fragment>
        <div
          className={`timetable__row ${isOpen ? "timetable__row_open" : ""}`}
        >
          <div className="timetable__day-wrapper">
            <DayInfo date={props.date} />
            <div className="timetable__wrapper-time">
              <Swiper
                spaceBetween={15}
                slidesPerView={"auto"}
                simulateTouch={false}
              >
                {timeItems}
              </Swiper>
            </div>
          </div>
          {isOpen ? <ReservationForm /> : <React.Fragment />}
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className={`timetable__row ${isOpen ? "timetable__row_open" : ""}`}>
        <div className="timetable__day-wrapper">
          <DayInfo date={props.date} />
          <div className="timetable__wrapper-time">{timeItems}</div>
        </div>
        {isOpen ? <ReservationForm /> : <React.Fragment />}
      </div>
    </React.Fragment>
  );
});

interface DayInfoProps {
  date: Date;
}

const DayInfo = (props: DayInfoProps) => {
  const { day, ruMonth, ruDayWeek } = replaceDate(props.date.toDateString());
  return (
    <span
      className={`timetable__date`}
    >{`${day} ${ruMonth}, ${ruDayWeek}`}</span>
  );
};

interface TimeItemProps {
  dispalyString: string;
  dateTimeString: string;
  rowIndex: number;
  dayOfWeek: DayOfWeek;
}

const TimeItem = (props: TimeItemProps) => {
  const dispatch = useDispatch();
  const isActive = useSelector(
    (state: AppState) =>
      !_.includes(state.questPage.quest.reservations, props.dateTimeString)
  );
  const isSelected = useSelector(
    (state: AppState) =>
      state.reservatinForm.dateTimeString === props.dateTimeString
  );

  return (
    <button
      className={`timetable__button ${
        !isActive
          ? "timetable__button_inactive"
          : isSelected
          ? "timetable__button_selected"
          : ""
      }`}
      disabled={!isActive}
      onClick={() => {
        dispatch(
          open({
            dateTimeString: props.dateTimeString,
            rowNumber: props.rowIndex,
            dayOfWeek: props.dayOfWeek,
          })
        );
      }}
    >
      {props.dispalyString}
    </button>
  );
};

interface ShowMoreButtonProps {
  newDaysCount: number;
}

const ShowMoreButton = (props: ShowMoreButtonProps) => {
  const dispatch = useDispatch();
  return (
    <button
      className="timetable__button-more"
      onClick={() => {
        dispatch(setTimeTableDaysShow(props.newDaysCount));
        dispatch(setIsTimeTableExpanded(true));
      }}
    >
      {" "}
      Показать больше{" "}
    </button>
  );
};

const SelectAnotherDateButton = () => {
  const dispatch = useDispatch();
  return (
    <button
      className="timetable__button-more"
      onClick={() => {
        dispatch(setShowAnotherDateForm(true));
      }}
    >
      {" "}
      Выбрать другую дату{" "}
    </button>
  );
};

interface DayRowWithDatepickerProps {
  openHours: any;
  rowIndex: number;
  timeStepMinutes: number;
  minimumDate: Date;
}

const DayRowWithDatepicker = (props: DayRowWithDatepickerProps) => {
  const dispatch = useDispatch();
  const minimumDateString = props.minimumDate.toISOString().split("T")[0]; //dateToString
  const dateString = useSelector((state: AppState) =>
    state.questPage.AnotherDateSelected === ""
      ? minimumDateString
      : state.questPage.AnotherDateSelected
  );
  const date = new Date(dateString);
  const dayOfWeek = date.getDay() as DayOfWeek;
  const openHour = props.openHours[dayOfWeek];

  return (
    <div className="timetable__wrapper-date-selector">
      <label htmlFor="date">Выберите дату:</label>
      <input
        className="timetable__date-selector"
        id="date"
        type="date"
        min={minimumDateString}
        value={dateString}
        onChange={(e: any) => {
          dispatch(changeAnotherDateSelected(e.target.value));
          dispatch(close());
        }}
      />
      <DayRow
        {...props}
        date={date}
        startHour={openHour.from}
        endHour={openHour.to}
      />
    </div>
  );
};

const toMinutesFromDayStart = (time: string) => {
  const [hours, minutes] = parseTimeString(time);

  return hours * 60 + minutes;
};
const parseTimeString = (time: string) => {
  return time.split(":").map((x) => parseInt(x));
};

function minutesToString(minutes: number) {
  return `${("0" + Math.floor((minutes / 60) % 24)).slice(-2)}:${(
    "0" +
    (minutes % 60)
  ).slice(-2)}`;
}

function dateToString(date: Date) {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
}

function addDaysToDate(date: Date, days: number) {
  const newDate = new Date(Number(date));
  newDate.setDate(date.getDate() + days);
  return newDate;
}

function calcDateOfTimezone(date: Date, offset: number) {
  // convert to msec
  // subtract local time zone offset
  // get UTC time in msec
  var utc = date.getTime() + date.getTimezoneOffset() * 60000;

  // create new Date object for different city
  // using supplied offset
  var nd = new Date(utc + 3600000 * offset);

  return nd;
}
function getDate(date: Date, minutesFromStart: number) {
  const newDate = new Date(
    addDaysToDate(date, Math.floor(minutesFromStart / (24 * 60)))
  );

  const hours = Math.floor((minutesFromStart / 60) % 24);
  const minutes = Math.floor(minutesFromStart % 60);

  newDate.setHours(hours);
  newDate.setMinutes(minutes);
  newDate.setSeconds(0);

  return newDate;
}
