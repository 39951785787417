import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import type { AppState } from "../../app/store";
import { createAsyncAction } from "../../middleware/asyncMiddleware";
import { close, submit } from "./formSlice";

import "./form.scss";

const Form = ({
    price,
    text,
    page,
}: {
    price: string;
    text: string;
    page: string;
}) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const { isWaiting } = useSelector((state: AppState) => state.form);
    const dispatch = useDispatch();

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        dispatch(
            createAsyncAction(
                submit.type,
                "certificates",
                "POST",
                { name: name, phone: phone, page: page },
                "Заявка успешно отправлена",
                "Произошла ошибка"
            )
        );

        dispatch(close());

        refreshForm();
    };

    const refreshForm = () => {
        setName("");
        setPhone("");
    };

    return (
        <>
            <form className="reservation-form" onSubmit={onFormSubmit}>
                <div className="reservation-form__wrapper-input">
                    <div className="reservation-form__wrapper-top-input">
                        <p className="reservation-form__wrapper-lable-input">
                            <label className="reservation-form__label">
                                Ваше имя
                            </label>
                            <input
                                required
                                className="reservation-form__input"
                                value={name}
                                placeholder="Имя"
                                type="text"
                                onChange={(e) => setName(e.target.value)}
                            />
                        </p>
                        <p className="reservation-form__wrapper-lable-input">
                            <label className="reservation-form__label">
                                Ваш телефон
                            </label>
                            <input
                                required
                                minLength={10}
                                type="text"
                                value={phone}
                                className="reservation-form__input"
                                id="phone"
                                placeholder="Телефон"
                                onChange={(e) => setPhone(e.target.value)}
                            />
                        </p>
                    </div>
                    <div className="reservation-form__wrapper-bottom-input">
                        <div>
                            <p className="reservation-form__price">
                                Цена от:
                                <span className="reservation-form__price-rub">{`${price} РУБ`}</span>
                            </p>
                        </div>
                        <div className="reservation-form__wrapper-button">
                            <button
                                type="submit"
                                className="reservation-form__button"
                                disabled={isWaiting}
                            >
                                {text}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Form;
