import React from "react";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";

import type { AppState } from "../../app/store";
import { close } from "../form/formSlice";

import "./Modal.scss";

const Modal = ({ children }: { children: React.ReactNode }) => {
    const { isOpen } = useSelector((state: AppState) => state.form);
    const dispatch = useDispatch();

    return (
        <div
            className={classNames("modal", { modal_active: isOpen })}
            onClick={() => dispatch(close())}
        >
            <div
                className="modal__content"
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </div>
        </div>
    );
};

export default Modal;
