import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import { Contact } from "../features/contacts/contacts-info";

interface PagesSliceState {
    title: string,
    contacts: Contact[],
    isLoadingContacts: boolean,
    mobileMenu: boolean
}

const initialState: PagesSliceState = {
    title: 'isolation-project',
    contacts: [],
    isLoadingContacts: false,
    mobileMenu: false,
};

const pagesSlice = createSlice({
    name: "pages",
    initialState: initialState,
    reducers: {
        init(state, action: PayloadAction<string>) {
            state.title = action.payload;
        },
        contactsLoad(state, action: PayloadAction<any>) {
            state.contacts = action.payload.contacts;
            state.isLoadingContacts = false;
        },
        contactsLoadBegin(state) {
            state.isLoadingContacts = true;
        },
        contactsLoadError(state) {
            state.isLoadingContacts = false;
        },
        hiddenMobileMenu(state) {
            state.mobileMenu = false;
        },
        showMobileMenu(state) {
            state.mobileMenu = true;
        },
        toggleMobileMenu(state) {
            state.mobileMenu = !state.mobileMenu;
        },

    }
});


export const {
    init,
    contactsLoad,
    contactsLoadBegin,
    contactsLoadError,
    hiddenMobileMenu,
    showMobileMenu,
    toggleMobileMenu
} = pagesSlice.actions;

export default pagesSlice.reducer;