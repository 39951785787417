import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";

export const QuestSlider = ({ images }: { images: string[] }) => {
    SwiperCore.use([Navigation]);

    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop
            navigation
            simulateTouch={false}
        >
            {images.map((img) => (
                <SwiperSlide key={img}>
                    <div
                        className="page-header__wrapper-text page-header__wrapper-text_info"
                        style={{
                            backgroundImage: `url(${img})`,
                            backgroundSize: "cover",
                        }}
                    ></div>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};
