import { useDispatch, useSelector } from "react-redux";
import { hiddenMobileMenu, init } from "../pagesSlice";
import React, { useEffect } from "react";
import {QuestsInfoPanel} from "../../features/quests-info-panel/quests-info-panel";
import { CompanyInfo } from "../../features/company-info/company-info";
import { AppState } from "../../app/store";
import { companyInfoLoad } from "./mainPageSlice";
import { createAsyncAction } from "../../middleware/asyncMiddleware";
import { push } from "connected-react-router";
import MainHeader from '../../features/main-header/main-header';
import Preloader from '../../components/preloader/preloader';
import {Helmet} from 'react-helmet';

import {Transition} from 'react-transition-group';

import './mainPage.scss';
import lampImage from "../../image/logo-about-us.png";

export default () => {
    const dispatch = useDispatch();

    const company = useSelector((state: AppState) => state.mainPage.company);
    const isLoading = useSelector((state: AppState) => state.mainPage.isLoading);
    dispatch(hiddenMobileMenu());

    if (!isLoading){
        document.body.style.position = '';
        document.body.style.top = '';
    }

    useEffect(() => {
        if (company.displayName)
            return;

        dispatch(init(company.displayName));
        dispatch(createAsyncAction(companyInfoLoad.type, '/organizations/1', "GET", null));
    }, [dispatch]);

    return (
        <React.Fragment>
            <Preloader toBe={isLoading}/>
            {/*<Helmet>*/}
            {/*    <title> {company.displayName} | Главная</title>*/}
            {/*    <meta name="description" content={company.description} />*/}
            {/*</Helmet>*/}
            <MainHeader title={company.displayName}/>
            <CompanyInfo company={company}/>
            <QuestsInfoPanel questsSummaries={company.questsSummaries} navigate={(name: string) => {dispatch(push(`/quest/${name}`));}}/>
        </React.Fragment>
    )
};
