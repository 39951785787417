import React from "react";

import { CertificateSlider } from "./ui/certificate-slider";
import СertificateChar from "../certificate-characteristics/certificate-characteristics";

import { PageHeaderLayout } from "../../components/page-header/page-header";

export const CertificateHeader = ({ price }: { price: string }) => {
    const howToGet = (
        <ul className="certificate-characteristics__list">
            <li>в электронном виде</li>
            <li>в запечатанном конверте с фирменной печатью</li>
            <li>лично в руки от любимого персонажа</li>
            <li>доставкой на дом</li>
        </ul>
    );

    const char = (
        <СertificateChar
            price={price}
            duration="3 месяца с момента покупки"
            howToGet={howToGet}
            design="на выбор разные варианты дизайна"
        />
    );

    return (
        <PageHeaderLayout
            slider={<CertificateSlider />}
            characteristics={char}
        />
    );
};
