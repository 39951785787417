import React from "react";
import { Characteristics } from "../../components/characteristics/characteristics";

import "./quest-characteristics.scss";

const QuestCharacteristics = ({
    duration,
    participantsCount: { from: playerFrom, to: playerTo },
    pricePerPersonFrom,
    fearLevel,
    singUp = true,
}: {
    duration: number;
    participantsCount: { from: number; to: number };
    pricePerPersonFrom: number;
    fearLevel: number;
    singUp?: boolean;
}) => {
    const info = (
        <>
            0/5 Полностью без страха, яркий свет, нет страшных декораций
            <br />
            <br />
            1/5 Полное овещение, возможен страшный декор
            <br />
            <br />
            2/5 Страшный декор, антуражное освещение, возможны скримеры
            <br />
            <br />
            3/5 Страшный декор, скримеры, умеренная игра актера
            <br />
            <br />
            4/5 Страшный декор, скримеры, плотный контакт с актером и
            механизамами
            <br />
            <br />
            5/5 Страшный декор, частые скримеры, актёр появляется очень часто,
            очень плотный контакт с актёром
        </>
    );

    const items = [
        {
            title: "Время прохождения",
            content: `${duration} минут`,
            className: "characteristics__content_time ",
        },
        {
            title: "Кол-во участников",
            content: `${playerFrom} — ${playerTo}`,
            className: "characteristics__content_players",
        },
        {
            title: "Цена",
            content: `от ${pricePerPersonFrom} руб/чел`,
            className: "characteristics__content_money",
        },
        {
            title: "Уровень страха",
            content: `${fearLevel} / 5`,
            info,
        },
    ];

    return (
        <Characteristics
            items={items}
            className="quest-characteristics"
            singUp={singUp}
        />
    );
};

export default QuestCharacteristics;
