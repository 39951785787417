import { useDispatch, useSelector } from "react-redux";
import { init } from "../pagesSlice";
import React, { useEffect } from "react";
import { TimeTable } from "../../features/new-timetable/timetable";
import { QuestInfo } from "../../features/quest-info/quest-info";
import { createAsyncAction } from "../../middleware/asyncMiddleware";
import { questLoad, setIsTimeTableExpanded } from "./questPageSlice";
import { useLocation } from "react-router";
import { AppState } from "../../app/store";
import { close } from "../../features/reservation-form/reservationFormSlice";
import './questPage.scss';
import './contacts.scss';
import './social.scss';
import Preloader from "../../components/preloader/preloader";
import { ErrorBoundary } from '../../components/error-boundary/error-boundary'

export default () => {
    const dispatch = useDispatch();
    const id = useLocation().pathname.split('/').pop();
    const quest = useSelector((state: AppState) => state.questPage.quest);
    const isLoading = useSelector((state: AppState) => state.questPage.isLoading);

    useEffect(() => {
        dispatch(createAsyncAction(questLoad.type, `/quests/${id}`, 'GET', null));
        dispatch(setIsTimeTableExpanded(false));
        dispatch(close());
    }, [dispatch, id]);

    dispatch(init(`Квест ${quest.title}`));

    if (isLoading)
        return (<Preloader toBe={isLoading} />);
    else {
        document.body.style.position = '';
        document.body.style.top = '';
    }

    const TimeTableWithPriceNotice = () =>
        <section className='enlist' id='enlist'>
            <h2 className='enlist__title'>Запись</h2>
            <div className='enlist__wrapper-content' >
                <ErrorBoundary>
                    <TimeTable />
                </ErrorBoundary>
            </div>
        </section>

    return (
        <React.Fragment>
            <Preloader toBe={isLoading} />
            <QuestInfo quest={quest} />
            <TimeTableWithPriceNotice />
        </React.Fragment>
    )
};
