import React from "react";
import ScrollDown from "../scroll-down/scroll-down";

import "./page-header.scss";

export const PageHeaderLayout = ({
    slider,
    characteristics,
}: {
    slider: React.ReactNode;
    characteristics: React.ReactNode;
}) => {
    return (
        <div className="page-header">
            {slider}
            {characteristics}
            <ScrollDown />
        </div>
    );
};
