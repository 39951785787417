import React, {useRef, useEffect} from 'react';
import lampImage from '../../image/loader.png';

import './preloader.scss';

import '../../../node_modules/animate.css/source/bouncing_entrances/bounceIn.css';
import {Transition} from 'react-transition-group';

let i = 0;

const Preloader = ({ toBe }) => {

    let preloader = useRef(null);


    const startScroll = () => {
        document.body.style.position = '';
        document.body.style.top = '';
    }

    const stopScroll = () => {
        document.body.style.position = 'fixed';
        document.body.style.top = `-${window.scrollY}px`;
    }

    const hidden = (el, transition = 0.5) => {
        el.style.transition = `${transition}s`;
        el.style.opacity= '0';
        setTimeout(() => {
            el.style.display = 'none';
        }, transition * 1000);
    }

    useEffect(() => {
        if(!toBe) {
            startScroll();
            // hidden(preloader.current);
        } else {
            stopScroll();
        }
    }, [toBe])

    return (
        <Transition
            in={toBe}
            timeout={300}
            unmountOnExit
        >
            {isLoading =>
                <div className={`preloader ${isLoading}`}>
                    <div className='preloader-bg'></div>
                    <div className='preloader-wrapper'>
                        <img className='preloader__img' src={lampImage}/>
                        <div className="cssload-container">
                            <div className="cssload-whirlpool"></div>
                        </div>
                        {/*<p className='animate_animated preloader__text'>*/}
                        {/*    Загрузка*/}
                        {/*    <span className='dot'>.</span>*/}
                        {/*</p>*/}
                    </div>
                </div>}
        </Transition>



    )
}

export default Preloader;
