import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Contact } from "../../features/contacts/contacts-info";

export class Quest {
    id: number = 0;
    title: string = "";
    slogan: string = "";
    description: string = "";
    contacts: Contact[] = [];
    reservations: string[] = []; //datetimes strings
    timezone: string = "+3"; //quest local timeZone
    pricePerPersonFrom: number = 0;
    durationTimeMinutes: number = 0;
    prepareTimeMinutes: number = 0;
    fearLevel: number = 0;
    participantsCount: { from: number; to: number } = { from: 0, to: 0 };
    openHours!: {
        [day in DayOfWeek]: { from: string; to: string };
    };
    priceHours!: {
        [day in DayOfWeek]: Array<{ from: string; to: string; price: number }>;
    };
    timeTableDaysShow: number = 7;
    mainPictureName: string = "";
    imagesNames: string[] = [];
    startingDate: string | null = null;
    endDate: string | null = null;
    unavailabilityPeriods: { from: string; to: string }[] | null = null;
}

export enum DayOfWeek {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 0,
}

interface QuestPageSliceState {
    AnotherDateSelected: string;
    isTimeTableExpanded: boolean;
    quest: Quest;
    isShowAnotherDateForm: boolean;
    isLoading: boolean;
}

const initialState: QuestPageSliceState = {
    quest: new Quest(),
    AnotherDateSelected: "",
    isTimeTableExpanded: false,
    isShowAnotherDateForm: false,
    isLoading: true,
};

const questPageSlice = createSlice({
    name: "questPage",
    initialState: initialState,
    reducers: {
        questLoad(state, action: PayloadAction<any>) {
            state.quest = action.payload;
            state.isLoading = false;
        },
        questLoadBegin(state) {
            state.isLoading = true;
        },
        questLoadError(state, action: PayloadAction<any>) {
            state.isLoading = false;
        },
        setTimeTableDaysShow(state, action: PayloadAction<number>) {
            state.quest.timeTableDaysShow = action.payload;
        },
        setIsTimeTableExpanded(state, action: PayloadAction<boolean>) {
            state.isTimeTableExpanded = action.payload;
        },
        setShowAnotherDateForm(state, action: PayloadAction<boolean>) {
            state.isShowAnotherDateForm = action.payload;
        },
        changeAnotherDateSelected(state, action: PayloadAction<string>) {
            state.AnotherDateSelected = action.payload;
        },
    },
});

export const {
    questLoad,
    setTimeTableDaysShow,
    setIsTimeTableExpanded,
    setShowAnotherDateForm,
    changeAnotherDateSelected,
} = questPageSlice.actions;

export default questPageSlice.reducer;
