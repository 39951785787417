import React from 'react';
import './background.scss';

const Background = () => {
    return (
        <>
        <div className='bacground-stars'>
            <div id='stars'></div>
            <div id='stars2'></div>
            <div id='stars3'></div>
        </div>
        </>
    )
}

export default Background;