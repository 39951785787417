export const replaceDate = (date) => {
    let dayWeek = date.slice(0, 3);
    let month = date.slice(4, 7);
    let day = date.slice(8, 10);
    let ruDayWeek, ruMonth, digitDay;

    switch (dayWeek) {
        case 'Mon' :
            ruDayWeek = 'Понедельник';
            break;
        case 'Tue' :
            ruDayWeek = 'Вторник';
            break;
        case 'Wed' :
            ruDayWeek = 'Среда';
            break;
        case 'Thu' :
            ruDayWeek = 'Четверг';
            break;
        case 'Fri' :
            ruDayWeek = 'Пятница';
            break;
        case 'Sat' :
            ruDayWeek = 'Суббота';
            break;
        case 'Sun' :
            ruDayWeek = 'Воскресенье';
            break;
        default:
            ruDayWeek = '';
    }

    switch (month) {
        case 'Jan':
            ruMonth = 'Января';
            break;
        case 'Feb':
            ruMonth = 'Февраля';
            break;
        case 'Mar':
            ruMonth = 'Март';
            break;
        case 'Apr':
            ruMonth = 'Апреля';
            break;
        case 'May':
            ruMonth = 'Май';
            break;
        case 'Jun':
            ruMonth = 'Июня';
            break;
        case 'Jul':
            ruMonth = 'Июля';
            break;
        case 'Aug':
            ruMonth = 'Август';
            break;
        case 'Sep':
            ruMonth = 'Сентября';
            break;
        case 'Oct':
            ruMonth = 'Октября';
            break;
        case 'Nov':
            ruMonth = 'Ноября';
            break;
        case 'Dec':
            ruMonth = 'Декабря';
            break;
        default: 
            ruMonth = '';
    }

    return {
        day,
        ruDayWeek,
        ruMonth
    };
}