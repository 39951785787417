import React from "react";
import classNames from "classnames";

export interface CharacteristicsItemProps {
    title: string;
    content: React.ReactNode;
    info?: React.ReactNode;
    className?: string;
}

export const CharacteristicsItem = ({
    title,
    content,
    info,
    className,
}: CharacteristicsItemProps) => {
    return (
        <li className="characteristics__item">
            <span className="characteristics__title">
                {title}
                {info ? (
                    <span className="characteristics__info">
                        <div className="characteristics__info-block">{info}</div>
                    </span>
                ) : null}
            </span>
            <div className={classNames("characteristics__content", className)}>
                {content}
            </div>
        </li>
    );
};
