import React from "react";
import { Quest } from "../../../pages/questPage/questPageSlice";
import { PageHeaderLayout } from "../../../components/page-header/page-header";
import { QuestSlider } from "./ui/quest-info-header-slider";
import QuestCharacteristics from "../../quest-characteristics/quest-characteristics";

const questImagesBasePath = process.env.REACT_APP_MEDIA_BASEPATH;

export const QuestInfoHeader = ({ quest }: { quest: Quest }) => {
    const {
        mainPictureName,
        participantsCount,
        durationTimeMinutes,
        pricePerPersonFrom,
        imagesNames,
        fearLevel,
    } = quest;

    const mainPicturePath =
        mainPictureName.indexOf("http://") === 0 ||
        mainPictureName.indexOf("https://") === 0
            ? mainPictureName
            : `${questImagesBasePath}${mainPictureName}`;

    const images = [
        mainPicturePath,
        ...imagesNames.map((img) => `${questImagesBasePath}${img}`),
    ];

    return (
        <PageHeaderLayout
            slider={<QuestSlider images={images} />}
            characteristics={
                <QuestCharacteristics
                    duration={durationTimeMinutes}
                    participantsCount={participantsCount}
                    pricePerPersonFrom={pricePerPersonFrom}
                    fearLevel={fearLevel}
                />
            }
        />
    );
};
