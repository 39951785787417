import React from 'react'

import './company-info.scss';
import imgLogo from '../../image/logo-about-us.png';
import _ from 'lodash';

interface CompanInfoProps {
    company: {
        description: string;
        displayName: string;
        imagesNames: string[];
    };
}

export const CompanyInfo = (props: CompanInfoProps) => {

    // const { description, displayName } = props.company;

    const textBlockFirst = props.company.description.slice(0, 175);
    const textBlockSecond = props.company.description.slice(176, 381);
    const textBlockThird = props.company.description.slice(382, 513);
    const textBlockFourth = props.company.description.slice(514);

    return (
        <React.Fragment>
            <section className='about-us' id='about'>
                <div className="about-us__wrapper-first-block-text">
                    {/*<img className='about-us__logo' src={imgLogo} alt='logo'/>*/}
                    <p className="about-us__description_plain">{props.company.description}</p>
                </div>
            </section>
        </React.Fragment>
    )
}
