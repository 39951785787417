import { ThunkAction } from "redux-thunk";
import {
    configureStore,
    Action,
    combineReducers,
    getDefaultMiddleware,
} from "@reduxjs/toolkit";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import pagesReducer from "../pages/pagesSlice";
import { asyncMiddleware } from "../middleware/asyncMiddleware";
import { getAxios } from "../configuration/axios-configurations";
import mainPageReducer from "../pages/mainPage/mainPageSlice";
import questPageReducer from "../pages/questPage/questPageSlice";
import reservationFormReducer from "../features/reservation-form/reservationFormSlice";
import formReducer from "../features/form/formSlice";

export const history = createBrowserHistory();

const rootReducer = combineReducers({
    router: connectRouter(history),
    pages: pagesReducer,
    mainPage: mainPageReducer,
    questPage: questPageReducer,
    reservatinForm: reservationFormReducer,
    form: formReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const store = configureStore({
    reducer: rootReducer,
    middleware: [
        routerMiddleware(history),
        asyncMiddleware(getAxios()),
        ...getDefaultMiddleware(),
    ],
    devTools: process.env.NODE_ENV !== "production",
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, AppState, null, Action<string>>;
export default store;
