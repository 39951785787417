import { HashLink } from 'react-router-hash-link';

import React from 'react'


export const NavigationButton = (props) => {

    return (
            <HashLink smooth to={props.path} className='nav-menu__link'>{props.text}</HashLink>
    )
}