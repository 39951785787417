import React from "react"
import { navigations } from "../../app-navigation";
import { NavigationButton } from "./navigation-button-link";
import {useDispatch, useSelector} from "react-redux";
import {toggleMobileMenu, hiddenMobileMenu, showMobileMenu} from '../../pages/pagesSlice';


import './navigation-menu.scss';
import {AppState} from "../../app/store";

export const NavigationMenu = (props: any) => {


    return(
        <nav className='nav-menu'>
            {navigations.map((item: any) => (
                <NavigationButton
                    elementId={item.elementId}
                    key={item.text}
                    text={item.text}
                    path={item.path}
                    append={item.append}
                />
            ))}
        </nav>
    );
}
