import React from 'react'
import ContactsView  from '../../features/contacts/contacts-info'

const CopyrightInfo = () => (
    <div>
        Copyright © 2018-{new Date().getFullYear()} Isolation Project.
        <br />
        All trademarks or registered trademarks are property of their
        respective owners.
    </div>);

export default () => {
    return (<React.Fragment>
        <ContactsView />
        {/* <CopyrightInfo /> */}
    </React.Fragment>
    );
}