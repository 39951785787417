import './index.scss';

import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import store, {history} from './app/store';
import {ConnectedRouter} from "connected-react-router";
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import SmoothScroll from 'smoothscroll-for-websites';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

if (window.innerWidth >= 1024) SmoothScroll({ animationTime: 1000, stepSize: 150 });

ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>
  ,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
