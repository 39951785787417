import React from "react";
import { Characteristics } from "../../components/characteristics/characteristics";

import "./certificate-characteristics.scss";

const CertificateChar = ({
    price,
    duration,
    howToGet,
    design,
}: {
    price: string;
    duration: string;
    howToGet: React.ReactNode;
    design: string;
}) => {
    const items = [
        {
            title: "Цена",
            content: `от ${price} руб`,
            className: "characteristics__content_money",
        },
        {
            title: "Срок действия",
            content: duration,
            className: "characteristics__content_time",
        },
        {
            title: "Как получить",
            content: howToGet,
        },
        {
            title: "Дизайн",
            content: design,
            info: "Листай карусель",
        },
    ];

    return (
        <Characteristics
            items={items}
            className="certificate-characteristics"
        />
    );
};

export default CertificateChar;
