import React from 'react';

import './scrollDown.scss';
import arrow3 from './arrow-button.png';

function ScrollDown(props) {
    const {classMod, text} = props;

    let classString = "scroll-down";

    if(classMod) {
        classString += ` ${classMod}`;
    }

    return (
            <div className={classString}>
                <div className='scroll-down__text'>{text}</div>
                <div className="scroll-down__icon">
                    <img src={arrow3} alt="arrow-down" />
                </div>
            </div>
    )
}

export default ScrollDown;