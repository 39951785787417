import mainPage from "./pages/mainPage/mainPage";
import questPage from "./pages/questPage/questPage";
import CertificatePage from "./pages/certificatePage/certificatePage";
import HolidayPage from "./pages/holidayPage/holidayPage";

export default [
    {
        path: '/',
        component: mainPage
    },
    {
        path: '/quest/:id',
        component: questPage
    },
    {
        path: '/certificate',
        component: CertificatePage
    },
    {
        path: '/holiday',
        component: HolidayPage
    }
];