import _, { find } from 'lodash';
import React, { useEffect } from 'react'
import { connect } from 'react-redux';
import { AppState } from '../../app/store';
import { createAsyncAction } from '../../middleware/asyncMiddleware';
import { contactsLoad } from '../../pages/pagesSlice';
import GoogleApiWrapper from '../maps/maps';

import './contacts-info.scss';

export class Contact {
    type: string = "";
    value: string = "";
}

interface ContactsViewProps {
    contacts: Contact[],
    LoadContacts: Function
}

export const ContactsView = (props: ContactsViewProps) => {
    useEffect(
        () => {
            if (props.contacts.length == 0) props.LoadContacts();
        }
    );

    return (
        <React.Fragment>
            <section className='contacts' id='contacts'>
                <h2 className='contacts__title'>Контакты</h2>
                <div className='contacts__wrapper-content'>
                    <div className='contacts__wrapper-left-column'>
                        <p className='contacts__info contacts__info_address'>{_.find(props.contacts, c => c.type === 'city')?.value} {_.filter(props.contacts, c => c.type === 'cityAddress').map(a => (<><br /> {a.value}</>))}</p>
                        <p className='contacts__info contacts__info_mail'>{_.filter(props.contacts, c => c.type === 'email').map(x => x.value)}</p>
                        <p className='contacts__info contacts__info_phone'>{_.filter(props.contacts, c => c.type === 'phone').map(x => (<a key={x.value} href={`tel:${x.value}`}>{x.value} <br/></a>))}</p>
                    </div>
                    <div className='contacts__wrapper-right-column'>
                    <iframe width="100%" height="100%" style={{border:0}} src={"https://www.google.com/maps/embed/v1/place?q=place_id:ChIJtwCz2KVDtEYRwbh_3an8heo&key=AIzaSyCxyDyxOdHRdxyKgj7_vCftrofI7YFfiL8"}></iframe>
                    {/* <GoogleApiWrapper/>  */}
                    </div>    
                </div>
            </section>
            <section className='social'>
                <a className='social__link social__link_inst' target="_blank" href={`https://instagram.com/${_.find(props.contacts, c => c.type == 'instagram')?.value}`}>Instagram</a>
                <a className='social__link social__link_vk' target="_blank" href={`https://vk.com/${_.find(props.contacts, c => c.type == 'vk')?.value}`}>vk</a>
            </section>
        </React.Fragment>
    );
}

const mapStateToProps = (state: AppState) => ({
    contacts: state.pages.contacts
});

const mapDispatchToProps = (dispatch: any) => {
    return {
        LoadContacts: () => dispatch(createAsyncAction(contactsLoad.type, '/organizations/1/contacts', "GET", null)),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsView)