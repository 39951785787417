/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';

import './video-section.scss';

const VideoSection = () => {
    return (
        <section className='video-wrapper' id='video'>    
            <iframe 
            className='video-frame'
            src="https://www.youtube-nocookie.com/embed/xVdnjfiGXz4?controls=0&amp;start=10" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>

            </iframe>
            
        </section>
    )
}

export default VideoSection;