import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DayOfWeek } from "../../pages/questPage/questPageSlice";

interface FormSliceState {
    personName: string;
    phoneNumber: string;
    participantsCount: number;
    isOpen: boolean;
    dateTimeString: string;
    isWaiting: boolean;
    rowNumber: number;
    dayOfWeek: DayOfWeek;
}

const initialState: FormSliceState = {
    personName: "",
    phoneNumber: "+7",
    participantsCount: 2,
    isOpen: false, // убрать флаг
    dateTimeString: "",
    isWaiting: false,
    rowNumber: -1, // сделать константу
    dayOfWeek: DayOfWeek.Monday,
};

const formSlice = createSlice({
    name: "reservationForm",
    initialState: initialState,
    reducers: {
        setPersonName(state, action: PayloadAction<string>) {
            state.personName = action.payload;
        },
        setPhoneNumber(state, action: PayloadAction<string>) {
            state.phoneNumber = action.payload;
        },
        setparticipantsCount(state, action: PayloadAction<number>) {
            state.participantsCount = action.payload;
        },
        open(
            state,
            action: PayloadAction<{
                dateTimeString: string;
                rowNumber: number;
                dayOfWeek: DayOfWeek;
            }>
        ) {
            state.dateTimeString = action.payload.dateTimeString;
            state.isOpen = true;
            state.rowNumber = action.payload.rowNumber;
            state.dayOfWeek = action.payload.dayOfWeek;
        },
        close(state) {
            state.isOpen = false;
            state.rowNumber = -1;
            state.dateTimeString = "";
        },
        reserve(state) {
            state.isOpen = false;
            state.rowNumber = -1;
            state.isWaiting = false;
        },
        reserveBegin(state) {
            state.isWaiting = true;
        },
        reserveError(state) {
            state.isOpen = false;
            state.isWaiting = false;
        },
    },
});

export const {
    setPersonName,
    setPhoneNumber,
    setparticipantsCount,
    open,
    close,
    reserve,
} = formSlice.actions;

export default formSlice.reducer;
