import React, { useState } from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import routes from './app-routes'
import './App.css';
import { Header } from './components/header/Header';
import Footer from './components/footer/footer';
import 'react-notifications-component/dist/theme.css'
import ReactNotification from 'react-notifications-component';
import Background from './components/background/background';
import { ErrorBoundary } from './components/error-boundary/error-boundary';


function App() {

  const [ screenWidth, setScreenWidth ] = useState(window.innerWidth);

  window.addEventListener('resize', (e) => {
    setScreenWidth(window.innerWidth);
  });

  return (
    <ErrorBoundary>
      <Background/>
      <Header/>
      <ReactNotification/>
      <main className='main'>
        <Switch>
          {routes.map((item: any) => (
              <Route
                  exact
                  key={item.path}
                  path={item.path}
                  component={item.component}
              />
          ))}
          <Redirect to={'/'}/>
        </Switch>
      </main>
      <Footer/>
    </ErrorBoundary>
  );
}

export default App;