import React from 'react';
import SwiperCore, { Autoplay, Navigation } from 'swiper';

import 'swiper/swiper.scss';
import './quest-info-body.scss';

export const QuestInfoBody = ({ quest :{ description, slogan } }) => {
    SwiperCore.use([Autoplay, Navigation]);

    return (
        <React.Fragment>
            <section className='description'>
                <h2 className='description__slogan'>{slogan}</h2>
                <p className='description__paragraph'>{description}</p>
            </section>
        </React.Fragment>
    )
}