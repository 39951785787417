import {createSlice, PayloadAction} from "@reduxjs/toolkit";

interface MainPageSliceState {
    company: Company,
    isLoading: boolean
}

class Company {
    description: string = '';
    displayName: string = '';
    imagesNames: string[] = [];
    questsSummaries: QuestShortInfo[] = [];
}

export class QuestShortInfo {
    id: number = 0;
    title: string = '';
    uniqueName: string = '';
    participantsCount: {from: number, to: number} = { from: 2, to: 5 };
    durationTimeMinutes: number = 0;
    pricePerPersonFrom: number = 0;
    fearLevel: number = 3;
}

const initialState: MainPageSliceState = {
    company: new Company,
    isLoading: true
};

const mainPageSlice = createSlice({
    name: "mainPage",
    initialState: initialState,
    reducers: {
        companyInfoLoad(state, action: PayloadAction<any>){
            state.company = action.payload;
            state.isLoading = false;
        },
        companyInfoLoadBegin(state){
            state.isLoading = true;
        },
        companyInfoLoadError(state, action: PayloadAction<any>){
            state.company = action.payload;
            state.isLoading = false;
        }
    }
});

export const {
    companyInfoLoad,
} = mainPageSlice.actions;

export default mainPageSlice.reducer;