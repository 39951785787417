import React, { useEffect } from 'react';
import './holidayPage.scss';
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { hiddenMobileMenu } from '../../pages/pagesSlice';
// import { QuestsInfoPanel } from '../mainPage/mainPageSlice';
import {QuestsInfoPanel} from '../../features/quests-info-panel/quests-info-panel';

function HolidayPage() {
    window.scrollTo(0, 0);

    const dispatch = useDispatch();
    const contacts = useSelector((state) => state.pages.contacts);
    const phone = _.find(contacts, c => c.type === 'phone');

    useEffect(() => {
        dispatch(hiddenMobileMenu());
    }, [dispatch]);

    return (
        <>
            <section className='holiday'>
                <h2 className='holiday__title'> Заказать мероприятие </h2>
                {/*<div className='holiday__wrapper-images'>*/}
                {/*    <img className='holiday__image' src={'https://avatars.mds.yandex.net/get-zen_doc/1328418/pub_5cc16e4124176a00ae4fd95f_5cc1702cad71e300b4a8c708/scale_1200'} />*/}
                {/*    <img className='holiday__image' src={'https://thumbs.dreamstime.com/b/%D0%BC%D0%BE%D0%BB%D0%BE%D0%B4%D0%BE%D0%B9-%D1%87%D0%B5%D0%BB%D0%BE%D0%B2%D0%B5%D0%BA-%D1%81-%D0%B2%D0%BA%D1%83%D1%81%D0%BD%D1%8B%D0%BC-%D1%82%D0%BE%D1%80%D1%82%D0%BE%D0%BC-%D0%B8-%D0%B5%D0%B3%D0%BE-%D0%B4%D1%80%D1%83%D0%B7%D1%8C%D1%8F%D0%BC%D0%B8-%D0%BD%D0%B0-%D0%B4%D0%BD%D0%B5-%D1%80%D0%BE%D0%B6%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F-%D0%B2-%D0%BA%D0%BB%D1%83%D0%B1%D0%B5-151117751.jpg'} />*/}
                {/*</div>*/}

                <div className="holiday-cards">
                    <div className="holiday-card">
                        <h3 className="holiday-card__title">Девичник. </h3>
                        <p className="holiday-card__discription">До знаменательного дня осталось совсем немного. Платье куплено, гости приглашены, подружки собрались на девичник проводить невесту. Что может пойти не так?
                            <br/>
                            По приезде в пункт назначения девушки начинают чувствовать что-то неладное, какая-то непонятная энергия царит в этом месте.
                            <br/>
                            Через некоторое время все узнают, что невеста оказалась проклятой, как только перешагнула порог этого дома. Ей больше не быть счастливой, если не успеть до полуночи снять проклятье.
                        </p>
                        <span className="holiday-card__time">Время: 40 - 80 минут. </span>
                        <span className="holiday-card__price">Цена: от 8 т.р.</span>
                    </div>
                </div>

                <p className='holiday__description'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci aperiam aut eaque et expedita, facere harum ipsa itaque magnam magni modi mollitia, obcaecati quis reprehenderit sed tempore, temporibus. Assumenda, atque cupiditate dolor illo nihil porro quaerat, quam quas quidem recusandae saepe vitae voluptatem. A adipisci architecto atque aut cum dignissimos dolor dolorem ducimus earum eligendi, et ex expedita facilis fugit id, labore nam non nulla numquam odit optio perspiciatis possimus quasi qui quia quibusdam repudiandae rerum sequi sint sit, ut vero voluptas voluptatibus. A accusantium alias animi eaque earum exercitationem harum labore numquam quasi repellendus. Harum itaque optio placeat quasi.</p>
                {phone ? //Дубляж кода, вынести в отдельный компонент
                    <React.Fragment>
                        <a href={`tel:${phone.value}`} className='holiday__button'>Позвонить для заказа мероприятия</a>
                        <p className='certificate__call-us'>Для заказа мероприятия звоните по номеру: <span>{phone.value}</span></p>
                    </React.Fragment> :
                    <React.Fragment />}
            </section>
        </>
    );
}

export default HolidayPage;
