import React from "react";
import { QuestShortInfo } from "../../pages/mainPage/mainPageSlice";
import QuestCharacteristics from "../quest-characteristics/quest-characteristics";

import "./quests-info-panel.scss";

interface QuestsInfoPanelProps {
    questsSummaries: QuestShortInfo[];
    navigate: (name: string) => void;
}

const questImagesBasePath = process.env.REACT_APP_MEDIA_BASEPATH;

export const QuestsInfoPanel = (props: QuestsInfoPanelProps) => {
    if (props.questsSummaries.length === 0) return <React.Fragment />;
    console.log(props);
    return (
        <React.Fragment>
            <section className="quest-section" id="quests">
                <h2 className="quest-section__title">наши квесты</h2>
                <div className="our-quests">
                    {props.questsSummaries.map((q) => (
                        <Panel key={q.id} {...q} navigate={props.navigate} />
                    ))}
                </div>
            </section>
        </React.Fragment>
    );
};

const Panel = (props: any) => {
    const mainPicturePath =
        props.mainPictureName.indexOf("http://") === 0 ||
        props.mainPictureName.indexOf("https://") === 0
            ? props.mainPictureName
            : `${questImagesBasePath}${props.mainPictureName}`;
    return (
        <div
            className="our-quests__item"
            style={{ backgroundImage: `url(${mainPicturePath})` }}
            onClick={() => {
                props.navigate(props.uniqueName);
                window.scrollTo(0, 0);
            }}
        >
            <QuestCharacteristics
                duration={props.durationTimeMinutes}
                participantsCount={props.participantsCount}
                pricePerPersonFrom={props.pricePerPersonFrom}
                fearLevel={props.fearLevel}
                singUp={false}
            />
        </div>
    );
};
