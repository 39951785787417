import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

import img1 from "../images/14feb.webp";
import img2 from "../images/call.webp";
import img3 from "../images/doll.webp";
import img4 from "../images/karych.webp";
import img5 from "../images/orig.webp";
import img6 from "../images/shelter.webp";

export const CertificateSlider = () => {
    SwiperCore.use([Navigation, Autoplay]);

    const images = [img1, img2, img3, img4, img5, img6];

    return (
        <Swiper
            spaceBetween={0}
            slidesPerView={1}
            loop
            navigation
            simulateTouch={false}
            autoplay={{ delay: 5000 }}
        >
            {images.map((image, index) => {
                return (
                    <SwiperSlide key={index}>
                        <div
                            className="page-header__wrapper-text page-header__wrapper-text_info"
                            style={{
                                backgroundImage: `url(${image})`,
                                backgroundSize: "contain",
                            }}
                        ></div>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    );
};
